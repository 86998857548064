.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.left-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  margin-right: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
}

.right-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
}

label {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
}

input {
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-top: 5px;
  width: 120%;
}

input[type="radio"] {
  margin-top: 20px;
}

button {
  background-color: #008cba;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #006080;
}

@media screen and (max-width: 768px) {
  .left-section {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .right-section {
    width: 100%;
  }
}

.Logo {
  margin: 20px auto;
}
